import { useEffect } from "react";

import "bootstrap/scss/bootstrap.scss";
import "../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../node_modules/sal.js/dist/sal.css";
import "../public/css/plugins/animation.css";
import "../public/css/plugins/euclid-circulara.css";
import "../public/css/plugins/feather.css";
import "../public/css/plugins/fontawesome.min.css";
import "../public/css/plugins/odometer.css";
// ========= Plugins CSS END =========

import "../public/scss/styles.scss";


export default function App({ Component, pageProps }) {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
// Service worker registration
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/serviceworker.js').then(registration => {
          console.log('Service worker registration successful:', registration);
        }, error => {
          console.log('Service worker registration failed:', error);
        });
      });
    }

    // sal({
    //   threshold: 0.01,
    //   once: true,
    // });
    function loadScript(a){
      var b=document.getElementsByTagName("head")[0],c=document.createElement("script");
      c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)
    }
    loadScript(function(){beTracker.t({hash:"11a30d38ed9e97db6890629fb5800d2d"})});
  }, []);
  return (
  // <GoogleOAuthProvider clientId="77524109234-cr3bf2782g8b3iikljs1kabk3155jlrl.apps.googleusercontent.com">
    // <LoginProvider>
      <Component {...pageProps} />
    // </LoginProvider>
  // </GoogleOAuthProvider>
  );
}
